<template>
  <div class="wrapper">
    <b-container>
      <b-row>
        <b-col>
          <p class="TextBlack32">{{ $t("Instruction") }}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mb-3">
          <!-- <div class="col-sm-12 mt-3 col-md-12 col-lg-12 text-center"> -->
           
              <div
                @click="$router.push({ name: 'uzasbo' })"
                style="min-height:280px"
                class="menuCard p-2 hover1 aboutButtons"
              >
                <div class="text-left ml-3 mt-3">
                 <img src="@/assets/img/calculating.svg" width="30%" alt=""/>
                </div>
                <p class="menuCardText24 text-left ml-3 mt-3">
                  {{ $t("Uzasbo") }}
                </p>
                <!-- <p class="menuCardText17 text-left ml-3 mt-3">
                  {{ $t("Uzasbo_info") }}
                </p> -->
              </div>
          
          <!-- </div> -->
        </b-col>
        <b-col class="mb-3">
          <!-- <div class="col-sm-12 mt-3 col-md-12 col-lg-12 text-center"> -->
           
              <div
                style="min-height:280px"
                class="menuCard p-2 hover1 aboutButtons"
                 @click="$router.push({ name: 'erp' })"
              >
                <div class="text-left ml-3 mt-3">
                  <img src="@/assets/img/erp.svg" width="30%" alt=""/>
                </div>
                <p class="menuCardText24 text-left ml-3 mt-3">
                  {{ $t("Erp") }}
                </p>
                <!-- <p class="menuCardText17 text-left ml-3 mt-3">
                  {{ $t("Erp_Info") }}
                </p> -->
              </div>
           
          <!-- </div> -->
        </b-col>
      </b-row>
      <b-row class="mb-3">
          <b-col sm="12" md="6" lg="6">
          <!-- <div class="col-sm-12 mt-3 col-md-12 col-lg-12 text-center"> -->
           
              <div
                style="min-height:280px"
                class="menuCard p-2 hover1 aboutButtons"
                 @click="$router.push({ name: 'bot' })"
              >
                <div class="text-left ml-3 mt-3">
                 <img src="@/assets/img/bot.svg" width="30%" alt=""/>
                </div>
                <p class="menuCardText24 text-left ml-3 mt-3">
                  {{ $t("Telegram_Bot") }}
                </p>
                <!-- <p class="menuCardText17 text-left ml-3 mt-3">
                  {{ $t("Telegram_Bot_Info") }}
                </p> -->
              </div>
          
          <!-- </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped></style>
